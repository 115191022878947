import React from "react";
import tw from "twin.macro";
import {Container as ContainerBase } from "src/ui-treact/components/misc/Layouts.js"
import styled from "styled-components";
import { footerObj } from '../../utils/footer';


// const Container = styled(ContainerBase)`bg-gray-900 text-gray-100 -mb-8 w-full`
const Container = styled(ContainerBase)`
  ${tw`relative  bg-center bg-cover w-full`}
  background-image: url(${props=>props.background_image});
`;
const Content = tw.div`flex items-center justify-center max-w-screen-xl mx-auto  pb-8 pt-32 lg:pb-12 pt-48`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-24`;


export default (props) => {
    console.log('props', props)
  return (
    <Container background_image={footerObj.background_image}>
      <Content>
          <LogoContainer>
            <LogoImg src={footerObj.logo} />
          </LogoContainer>
      </Content>
    </Container>
  );
};
